import React, {Component} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Images from '../utilies/Images';

import swal from 'sweetalert';

import {getCookie, removeCookie} from '../inc/cookies';
import config from '../inc/config';
import clientData from '../inc/clientData';

const value = 1;

class PanelBaseModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigationList: [
        {id: 1, icon: 'fa-dashboard', color: 'text-warning', title: 'Kontrol Paneli', items: [], url: '/home'},
        {id: 2, icon: 'fa-user', color: 'text-danger', title: 'Bana Özel', items: [
          {id: 20, icon: 'fa-angle-double-right', title: 'Randevularım', url: '/myAppointments'},
          {id: 21, icon: 'fa-angle-double-right', title: 'Satışlarım', url: '/mySales'},
          {id: 22, icon: 'fa-angle-double-right', title: 'Zimmetlerim', url: '/myWorkItems'},
          {id: 23, icon: 'fa-angle-double-right', title: 'Harcamalarım', url: '/myWorkExpenses'}
        ]},
        {id: 3, icon: 'fa-th', color: 'text-info', title: 'İlanlar', items: [
          {id: 30, icon: 'fa-angle-double-right', title: 'İlanlar', url: '/adverts'},
          {id: 31, icon: 'fa-angle-double-right', title: 'Onay Bekleyen İlanlar', url: '/pendingAdverts'}

        ]},
        {id: 4, icon: 'fa-list', color: 'text-success', title: 'Kategoriler', items: [
          {id: 40, icon: 'fa-angle-double-right', title: 'Kategoriler', url: '/categories'},
          {id: 41, icon: 'fa-angle-double-right', title: 'Özel Alanlar', url: '/categoryFields'},
          {id: 42, icon: 'fa-angle-double-right', title: 'Özellikler', url: '/categoryAttributes'},
          {id: 43, icon: 'fa-angle-double-right', title: 'Özellik Grupları', url: '/attributeGroups'},
        ]},
        {id: 5, icon: 'fa-asterisk', color: 'text-danger', title: 'CRM', items: [
          {id: 50, icon: 'fa-angle-double-right', title: 'Potansiyel Üyeler', url: `/crmMembers`},
          {id: 51, icon: 'fa-angle-double-right', title: 'Nihai Üyeler', url: `/crmMembers?filterIsMember=${value}`},
          {id: 52, icon: 'fa-angle-double-right', title: 'Yeni Potansiyel Üye Ekle', url: '/crmMemberAdd'},
          {id: 53, icon: 'fa-angle-double-right', title: 'Randevu Sistemi', url: '/crmMemberAppointments'}
        ]},
        {id: 6, icon: 'fa-user', color: 'text-warning', title: 'Üyeler', items: [
          {id: 60, icon: 'fa-angle-double-right', title: 'Üyeler', url: '/members'},
          {id: 61, icon: 'fa-angle-double-right', title: 'Yeni Üye Ekle', url: '/memberAdd'},
          {id: 62, icon: 'fa-angle-double-right', title: 'Satış Geçmişi', url: '/salesHistory'}
        ]},
        /* {id: 7, icon: 'fa-desktop', color: 'text-info', title: 'Bannerlar', items: [
          {id: 70, icon: 'fa-angle-double-right', title: 'Bannerlar', url: '/banners'}
        ]},
        {id: 8, icon: 'fa-tags', color: 'text-success', title: 'Bilgi Sayfaları', items: [
          {id: 80, icon: 'fa-angle-double-right', title: 'Blog', url: '/blogs'},
          {id: 81, icon: 'fa-angle-double-right', title: 'Yardım', url: '/helps'}
        ]},
        {id: 9, icon: 'fa-calculator', color: 'text-warning', title: 'Muhasebe', items: [
          {id: 90, icon: 'fa-angle-double-right', title: 'Şirket Çalışan Masrafları', url: '/memberWorkExpenses'},
          {id: 91, icon: 'fa-angle-double-right', title: 'Tüm Masraflar', url: '/workExpenses'}
        ]}, */
        {id: 10, icon: 'fa-credit-card', color: 'text-danger', title: 'Servisler', items: [
          {id: 100, icon: 'fa-angle-double-right', title: 'Kredi Başvuruları', url: '/creditRequests'},
          {id: 101, icon: 'fa-angle-double-right', title: 'Ekspertiz Başvuruları', url: '/expertises'},
          {id: 102, icon: 'fa-angle-double-right', title: 'Çekici Başvuruları', url: '/towingRequests'}
        ]},
        {id: 11, icon: 'fa-tags', color: 'text-info', title: 'İş Gereçleri', items: [
          {id: 110, icon: 'fa-angle-double-right', title: 'İş Araç Gereçleri', url: '/workItems'},
          {id: 111, icon: 'fa-angle-double-right', title: 'Zimmet Listesi', url: '/memberWorkItems'}
        ]},
        {id: 12, icon: 'fa-cog', color: 'text-success', title: 'Site Yönetimi', items: [
          {id: 120, icon: 'fa-angle-double-right', title: 'Potansiyel Üye Tipleri', url: '/crmMemberTypes'},
          {id: 121, icon: 'fa-angle-double-right', title: 'Potansiyel Üye Durumları', url: '/crmMemberAppointmentStatuses'},
          {id: 122, icon: 'fa-angle-double-right', title: 'Ödeme Tipleri', url: '/paymentTypes'},
          {id: 123, icon: 'fa-angle-double-right', title: 'Ödeme Durumları', url: '/paymentStatuses'},
          {id: 124, icon: 'fa-angle-double-right', title: 'Telefon Tipleri', url: '/phoneTypes'},
          {id: 125, icon: 'fa-angle-double-right', title: 'Ürünler', url: '/products'},
          {id: 126, icon: 'fa-angle-double-right', title: 'Ürün Tipleri', url: '/productTypes'},
          {id: 127, icon: 'fa-angle-double-right', title: 'Reklam Alanları', url: '/bannerLocations'},
          {id: 128, icon: 'fa-angle-double-right', title: 'Gizlilik Sözleşmesi', url: '/agreements'},
          {id: 129, icon: 'fa-angle-double-right', title: 'Diğer Portallar', url: '/otherPortals'}
        ]},
        {id: 13, icon: 'fa-bar-chart', color: 'text-primary', title: 'Raporlar', items: [
          {id: 130, icon: 'fa-angle-double-right', title: 'Aktif Üye Raporları', url: '/reports/activeMembers'},
          {id: 131, icon: 'fa-angle-double-right', title: 'Günlük Ziyaretçi Raporları', url: '/reports/crmMemberAppointments'},
          {id: 132, icon: 'fa-angle-double-right', title: 'Paketi Biten Üye Raporları', url: '/reports/expiredMembers'},
          {id: 132, icon: 'fa-angle-double-right', title: 'Personel Hareketleri', url: '/reports/personalEvents'}
        ]},
      ],

      memberInfo: {
        id: '',
        firstname: '',
        lastname: '',
        username: '',
        image: null
      }
    }

    this.userLogoutOnClick = this.userLogoutOnClick.bind(this);
  }

  componentDidMount() {
    let sessionHash = getCookie('sessionHash');
    
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberInfo', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          clientData.id = responseData.memberInfo.id
          this.setState({memberInfo: responseData.memberInfo});
        break;
        case 400:
          this.props.navigate('/');
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

    document.querySelector(".navbar-minimalize").addEventListener("click", this.menuToggle);
    window.$('#side-menu').metisMenu();

    if (window.innerWidth < 769) {
      document.body.classList.add("body-small");
    } else {
        document.body.classList.remove("body-small");
    }
  }

  componentWillUnmount() {
    document.querySelector(".navbar-minimalize").removeEventListener("click", this.menuToggle);
  }
  
  menuToggle() {
    document.body.classList.toggle("mini-navbar");
  }

  userLogoutOnClick(event) {
    event.preventDefault();

    swal({
      dangerMode: true,
      icon: 'info',
      title: 'Onayla',
      text: 'Oturumu kapatmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then((value) => {
      if (value) {
        let sessionHash = getCookie('sessionHash');
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'logout', requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              removeCookie('sessionHash');
              this.props.navigate('/');
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return (
      <div id="wrapper">
        <nav className="navbar-default navbar-static-side navbar-border">
          <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu">
              <li className="nav-header text-center">
                  <div className="dropdown profile-element">
                  {this.state.memberInfo.image ? <img alt="userImage" className="rounded-circle w-50 h-50" src={this.state.memberInfo.image} /> : <img alt="userImage" className="rounded-circle w-50 h-50" src={Images.userImage} />}
                  <a className="dropdown-toggle" data-toggle="dropdown" href="!#">
                    <span className="block font-bold text-white mt-1">{this.state.memberInfo.firstname} {this.state.memberInfo.lastname}</span>
                    <span className="text-muted block">{this.state.memberInfo.username} <b className="caret"></b></span>
                  </a>
                  <ul className="dropdown-menu animated fadeInRight mt-1">
                    <li>
                    <Link className="dropdown-item" to="#"><i className="fa fa-user mr-2"></i> Hesabım</Link>
                    </li>
                  </ul>
                  </div>
              </li>
              {this.state.navigationList.map(navigationInfo =>
                <li key={navigationInfo.id}>
                  <Link to={navigationInfo.url || '#'}><i className={"fa " + navigationInfo.icon + " " + navigationInfo.color + " " + "font-bold"}></i> <span className="nav-label text-white font-normal">{navigationInfo.title}</span> {navigationInfo.items.length > 0 ? <span className="fa fa-caret-down float-right"></span> : null}</Link>
                  {navigationInfo.items.length > 0 ?
                    <ul className="nav nav-second-level">
                      {navigationInfo.items.map(itemInfo => <li key={itemInfo.id}><Link to={itemInfo.url}><i className={"fa " + itemInfo.icon}></i> {itemInfo.title}</Link></li>)}
                    </ul>
                  : null}
                </li>
              )}
            </ul>
          </div>
        </nav>
        <div id="page-wrapper" className="bg-mainpage">
          <div className="row border-bottom d-block">
            <nav className="navbar navbar-static-top bg-darkblue p-0">
              <div className="navbar-header">
                <Link className="navbar-minimalize minimalize-styl-2 btn navbar-button" to="#"><i className="fa fa-bars"></i></Link>
                <Link className="header-logo" to="/home"><img alt="Logo" className="ml-3 mt-3 w-0 h-0" src={Images.wlogo} /></Link>
              </div>
              <ul className="nav navbar-top-links navbar-right">
                <li>
                  <div className="bg-orange">
                    <a className="d-none d-sm-block logout text-white" onClick={this.userLogoutOnClick} title="Oturumu Kapat" href="!#">Oturumu Kapat <i className="fa fa-sign-out"></i></a>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="wrapper wrapper-content">
            {this.props.children}
          </div>
          <div className="footer">
            <div className="text-center text-darkblue d-sm-none">
              <strong>Copyright</strong> SG GARANTİ BİLİŞİM &copy; 2019 - 2024
            </div>
            <div className="float-right text-darkblue d-none d-sm-block">
              <strong>Copyright</strong> SG GARANTİ BİLİŞİM &copy; 2019 - 2024
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function PanelBase(props) {
  return (
    <PanelBaseModule navigate={useNavigate()}>{props.children}</PanelBaseModule>
  )
}